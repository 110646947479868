import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Heading, Box } from 'rebass'
import FAQ from '../components/base/FAQ'
import Hero from '../components/base/Hero'
import BackedByIag from '../components/base/BackedByIag'

const FAQs = () => (
  <StaticQuery query={graphql`
    query FAQsQuery {
      allDatoCmsFaqCategory(sort: { fields: position }) {
        edges {
          node {
            id
            name
          }
        }
      }
      allDatoCmsFaq {
        edges {
          node {
            id
            slug
            categories {
              id
            }
            question
            answerNode {
              childMarkdownRemark {
                html
              }
            }
            relevantFaqs {
              id
              slug
              question
            }
          }
        }
      }
    }
  `}
  render={data => (
    <>
      <Hero sx={{ py: 0 }}>
        <Heading as="h1" variant="hero.heading">FAQs</Heading>
      </Hero>
      <Box my={5} variant="fixed">
        {data.allDatoCmsFaqCategory.edges.map(({ node: category }) =>
          <Box key={category.id} mb={80}>
            <Heading
              as="h3"
              variant='heading'
              fontWeight={500}
              lineHeight={1.6}
            >
              {category.name}
            </Heading>
            {data.allDatoCmsFaq.edges.filter(({ node }) => node.categories.map(({ id }) => id).includes(category.id)).map(({ node: faq }) =>
              <Box key={faq.id} my={2}>
                <FAQ
                  question={faq.question}
                  answer={faq.answerNode.childMarkdownRemark.html}
                  relevant={faq.relevantFaqs}
                  slug={faq.slug}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <BackedByIag mt={5} />
    </>
  )}
  />
)

export default FAQs
