import React, { useState } from 'react'
import { Heading, Flex, Box, Text, Image } from 'rebass'
import Link from './Link'
import Icon from '../../assets/icons/faq.svg'
import LinkIcon from '../../assets/icons/link.svg'
import { modelSlugToRoute } from '../../utils/links'

const FAQ = ({ question, answer, relevant, slug, ...props }) => {
    const [show, setShow] = useState(false)

    return (
        <Box {...props}>
            <Flex>
                <Box width={33} flexShrink={0}>
                    <Image
                        src={Icon}
                        sx={{
                            transform: show ? 'rotate(90deg)' : null,
                            transition: 'transform 0.2s ease-out',
                            cursor: 'pointer',
                            mt: '-2px',
                            ml: '3px'
                        }}
                    />
                </Box>
                <Heading
                    as="button"
                    variant="question"
                    onClick={() => setShow(!show)}
                    sx={{
                        p: 0
                    }}
                >
                    {question}
                    <Link to={modelSlugToRoute('faq', slug)} sx={{ ml: 1 }}>
                        <Image
                            src={LinkIcon}
                            alt='Canonical link'
                            sx={{ width: 15 }} />
                    </Link>
                </Heading>
            </Flex>
            {show && (
                <Box variant="answer">
                    <Text dangerouslySetInnerHTML={{ __html: answer }} />
                    {relevant.length > 0 && (
                        <Box>
                            <Heading fontSize={0}>Related Questions</Heading>
                            <ul>
                                {relevant.map(r => <li key={r.id}>
                                    <Link to={modelSlugToRoute('faq', r.slug)}>{r.question}</Link>
                                </li>)}
                            </ul>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
}

FAQ.defaultProps = {
    relevant: []
}

export default FAQ
